import React from "react";
import PropTypes from "prop-types";
import "prismjs/themes/prism-okaidia.css";

import asyncComponent from "../AsyncComponent";
import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Meta from "./Meta";
import Author from "./Author";
import Comments from "./Comments";
import NextPrev from "./NextPrev";
import Categories from  "../../components/Category";
const Share = asyncComponent(() =>
  import("./Share")
    .then(module => {
      return module.default;
    })
    .catch(error => {})
);


class Support extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: [],
      selectedcat: ''
    }
    this.SelectCategory = this.SelectCategory.bind(this)
  }

  SelectCategory(key) {
  }

  componentDidMount() {
      let category = [];
      category.push(
        {
          'title': 'Support'
        }
      )
      category.push(
        {
          'title': 'News'
        }
      )
      this.setState({
        category: category,
        selectedcat: 'Support'
      })
  }
  render() {
    const {
      post,
      post: {
        html,
        fields: { prefix, slug },
        frontmatter: { title, author, category }
      },
      authornote,
      facebook,
      next: nextPost,
      prev: prevPost,
      theme
    } = this.props;

    return (
      <React.Fragment>
        <Categories title={title} categories = {this.state.category} selectedcat={this.state.selectedcat} onClick={this.SelectCategory} />
        <div className="each-blog">
          <Headline title={title} theme={theme} />
          <Meta prefix={prefix} author={author} category={category} theme={theme} />
          <Bodytext html={html} theme={theme} />
          <footer>
            <Share post={post} theme={theme} />
            <Author note={authornote} theme={theme} />
            <NextPrev next={nextPost} prev={prevPost} theme={theme} />
            {/* <Comments slug={slug} facebook={facebook} theme={theme} /> */}
          </footer>
          <style jsx>{`
            .each-blog {
              margin: 0 auto;
              max-width: 65em;
              padding: 10px;
            }
          `}</style>
        </div>
      </React.Fragment>
    );
  }
}

Support.propTypes = {
  post: PropTypes.object.isRequired,
  authornote: PropTypes.string.isRequired,
  facebook: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default Support;
