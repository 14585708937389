import React from "react";
import { categories } from "../../../config";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class Categories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: []
    }
    this.set_categories = this.set_categories.bind(this)
  }

  componentDidMount() {
  }

  set_categories(nextProps) {
    let cate = nextProps.categories
    let rlt = []
    if ( cate.length === 0 ) return;
    if (cate[0].order === undefined) return;
    let flag = []
    for (let i = 0 ; i < cate.length ; i++) {
      flag.push({
        selected: false
      })
    }
    for ( let i = 0 ; i < cate.length ; i++) {
      let minval = 999
      let pos = 0;
      for (let j = 0 ; j < cate.length ; j++) {
        if (flag[j].selected) continue;
        if (minval > cate[j].order) {
          minval = cate[j].order
          pos = j;
        }
      }
      flag[pos].selected = true
      rlt.push(cate[pos])
    }
    this.setState({
      categories: rlt
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      categories: nextProps.categories
    })
    this.set_categories(nextProps)
  }
  render() {
    let background = '#0098e4'
    if (this.props.selectedcat === 'purple') {
      background = 'rgb(123, 67, 173)'
    }
    return (
      <div className="Categories_container" 
        style={{'backgroundColor': background}}
      >
        <div className="category_container">
          <ul className="menu categories">
            {this.state.categories.map((item, index) => {
              let underline = "";
              let too = "";
              if (item.title === this.props.selectedcat) {
                underline = "selected";
              }
              if (item.title === "About Us") {
                too = "/about"
              } else if (item.title === "Contact Us") {
                too = "/contact"
              } else if(item.title === "Support") {
                too="/support"
              } else if(item.title === "News") {
                too = "/news"
              } else if(item.target !== undefined && item.target === 'none') {
                too = window.location.pathname
              } else {
                too = '/products?category_' + item.title
              }
              return (
                <div key={index} className="categories_con">
                  <li
                    className={underline}
                    onClick={e => {
                      this.props.onClick(index);
                    }}
                  >
                    <Link
                      to={too}
                      className="news-item"
                      state={{
                        index: index
                      }}
                    >
                      {item.title}
                    </Link> 
                  </li>
                  {/* {
                    this.props.title !== undefined ?
                    <div className="support_title">
                      <i className="fa fa-angle-double-right"></i>
                      {this.props.title}
                    </div>
                    :
                    <div></div>
                  } */}
                </div>
              );
            })}
          </ul>
        </div>
        <style jsx>{`
          .categories_con {
            display: flex;
            justify-content: center;
            align-items: center;
            .support_title {
              color: #fff !important;
              font-size: 0.9375rem !important;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.04688rem;
            }
          }
          .Categories_container {
          }
          .category_container {
            max-width: 72.75em;
            margin-left: auto;
            margin-right: auto;
          }
          .categories {
            display: flex;
            justify-content: space-around;
            list-style: none;
            flex-wrap: wrap;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            .selected {
              border-bottom: 0.125rem solid #052b6c;
            }
            :global(a:hover) {
              color: #052b6c !important;
            }
            li {
              margin-right: 0.7rem;
              margin-left: 0.7rem;
              padding-top: 0.3125rem;
              padding-bottom: 0.3125rem;
              a {
                color: #fff !important;
                font-size: 0.9375rem !important;
                line-height: 1.125rem !important;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.04688rem;
              }
            }
          }
        `}</style>
      </div>
    );
  }
}

Categories.propTypes = {
  onClick: PropTypes.func,
  selectedcat: PropTypes.string,
  productdetail: PropTypes.number,
  name: PropTypes.string,
  category: PropTypes.string
};

export default Categories;
